
const URL = process.env.VUE_APP_URL_BASE_PRIVATE
const headers = {
   'Content-Type': 'application/json',
   'Accept': 'application/json',
   'x-functions-key': process.env.VUE_APP_CODE_BASE_PRIVATE
 }
// import FormData from 'form-data';

export function user (cb, errorCb) {
    window.axios({
      method: 'get',
      url: URL+'user',
      headers: headers
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function getProfilePicture (data,cb, errorCb) {
    window.axios({
      method: 'get',
      url: URL+'profile-picture?tenantId='+data.tenantId+'&siteId='+data.siteId+'&profilePicture='+data.profilePicture,
      headers: headers
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function uploadProfilePicture(data,cb,errorCb){
    window.axios({
        method: 'post',
        url: URL+'profile-picture',
        headers: headers,
        data: data
      }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}

export function getForm(data){
    return window.axios({
        method: 'get',
        url: URL+'form?tenantId='+data.tenantId+'&siteId='+data.siteId,
        headers: headers
      })
}

export function getFormResult(data,cb,errorCb){
    window.axios({
        method: 'get',
        url: URL+'form-result?tenantId='+data.tenantId+'&siteId='+data.siteId+'&offset='+data.offset+'&limit='+data.limit,
        headers: headers
    }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}

export async function postAwaitFormResult({data}){
 
  try{
    const resp = await window.axios({
        method: 'post',
        url: URL+'form-result',
        headers: headers,
        data: data
    })
    return resp
  }catch (error){
    console.log(error)
    throw error
  }
}

export function postFormResult({data},cb,errorCb){
  window.axios({
      method: 'post',
      url: URL+'form-result',
      headers: headers,
      data: data
  }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function ocrIdcard(data,cb,errorCb){
    window.axios({
        method: 'post',
        url: URL+'ocr-idcard',
        headers: headers,
        data: data
    }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}

export function pdpaContent(data,cb,errorCb){
    window.axios({
        method: 'get',
        url: URL+'content?contentType='+data,
        headers: headers
    }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}

export function consent(data,cb,errorCb){
    window.axios({
        method: 'post',
        url: URL+'consent',
        headers: headers,
        data: data
    }).then(response => {
        cb(response)
      }).catch(error => {
        errorCb(error)
      })
}

export function announcement(data){
  return window.axios({
    method: 'get',
    url: URL+'announcement?tenantId='+data.tenantId+'&siteId='+data.siteId+'&offset='+data.offset+'&limit='+data.limit,
    headers: headers
  })
}

export function announcementBanner(data){
   return window.axios({
        method: 'get',
        url: URL+'announcement-banner?tenantId='+data.tenantId+'&siteId='+data.siteId+'&bannerName='+data.bannerName,
         headers: headers
    })

}

export function fromLogo(data){
  return window.axios({
    method: 'get',
    url: URL+'form-logo?tenantId='+data.tenantId+'&siteId='+data.siteId+'&formLogo='+data.formLogo,
     headers: headers
})
}

export function ocrMeddevice(param,formData,cb,errorCb){
  // var data = new FormData();
  // data.append('file', encode);
  // console.log(data.getHeaders())
  window.axios({
      method: 'post',
      url: URL+'ocr-meddevice?modelId='+param.modelId+'&appKey=' + param.appKey,
      headers: {...headers, 'Content-Type':'multipart/form-data'},
      data: formData
  }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}

export function updateUserProfile (param,data,cb,errorCb) {
  window.axios({
    method: 'patch',
    url: URL+'user?id='+param.id+'&_etag='+param.etag,
    data: data,
    headers: headers
}).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getNewsInbox (param) {
  return window.axios({
    method: 'get',
    url: URL+'news-inbox?offset='+param.offset+'&limit='+param.limit,
    headers: headers
  })
}

export function getNewsFile (param) {
  return window.axios({
    method: 'get',
    url: URL+'news-file?fileName=' + param.fileName,
    headers: headers
  })
}

export function postNewsLog (data,cb,errorCb) {
  window.axios({
    method: 'post',
    url: URL+'news-log',
    headers: headers,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postFormResultFile (data,cb,errorCb) {
  window.axios({
    method: 'post',
    url: URL+'form-result-file',
    headers: headers,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}
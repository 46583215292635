<template>
     <v-app-bar app color="primary" v-bind="$attrs" >
    <v-app-bar-nav-icon @click="$router.go(-1)" v-if="backward">
       <template slot="default">
           <v-btn icon color="natural" ><v-icon>mdi-arrow-left</v-icon></v-btn>
       </template>
     
         </v-app-bar-nav-icon>
         <v-spacer/>
  <div class="text-title-1 natural--text text-no-wrap">{{title}}</div> 
     <!-- <v-app-bar-title class="text-title-1 natural--text text-no-wrap">{{title}}</v-app-bar-title> -->
        <v-spacer/>
<v-btn v-if="backward" icon></v-btn>
     </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'หัวข้อแสดงผล H4' 
    },
    backward:{
      type: Boolean,
      default: false
    }
  },

}
</script>

<style>

</style>